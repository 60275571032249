import React, { useState, useEffect, useCallback } from 'react';
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    IconButton,
    CircularProgress,
    Paper,
    Snackbar,
    Alert
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon, Description as DescriptionIcon } from '@mui/icons-material'; // Import DescriptionIcon
import axios from 'axios';
import { useAuth } from './auth';
import { useNavigate } from 'react-router-dom';

const Questions = () => {
    const { token } = useAuth();
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const apiUrl = process.env.REACT_APP_BASE_USER_SERVER;
    const navigate = useNavigate();

    const fetchQuestions = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/api/questions`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setQuestions(response.data);
        } catch (error) {
            console.error('Error fetching questions', error);
        } finally {
            setLoading(false);
        }
    }, [token]);

    useEffect(() => {
        fetchQuestions();
    }, [fetchQuestions]);

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${apiUrl}/api/questions/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setSnackbarMessage('Question deleted successfully');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            await fetchQuestions();
        } catch (error) {
            setSnackbarMessage('Error deleting question');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            console.error('Error deleting question', error);
        }
    };

    const formatDateTime = (dateTime) => {
        if (!dateTime) return '';
        const date = new Date(dateTime);
        const formattedDate = date.toISOString().split('T')[0];
        const formattedTime = date.toTimeString().split(' ')[0];
        return `${formattedDate} ${formattedTime}`;
    };

    const isExpired = (expiresAt) => {
        const now = new Date();
        const expirationDate = new Date(expiresAt);
        return expirationDate < now;
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container style={{ marginTop: '20px' }}>
            <Button variant="outlined" color="primary" onClick={() => navigate('/questions/new')}>
                Add Question
            </Button>
            {loading ? (
                <CircularProgress />
            ) : (
                <TableContainer component={Paper} sx={{ width: '100%', overflowX: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Text</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Expiration Date</TableCell>
                                <TableCell>International</TableCell>
                                <TableCell>Published</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {questions.map((question) => (
                                <TableRow key={question.id}>
                                    <TableCell>{question.text}</TableCell>
                                    <TableCell>{question.questionType}</TableCell>
                                    <TableCell style={{ color: isExpired(question.expiresAt) ? 'red' : 'green' }}>
                                        {formatDateTime(question.expiresAt)}
                                    </TableCell>
                                    <TableCell>{question.international ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>{question.published ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => navigate(`/questions/edit/${question.id}`)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => handleDelete(question.id)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => navigate(`/questions/statistics/${question.id}`)} // Navigate to report route
                                        >
                                            <DescriptionIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default Questions;
