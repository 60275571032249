import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import Questions from './Questions';
import QuestionForm from './QuestionForm';
import Users from './Users';
import Login from './Login';
import Navbar from './Navbar';
import PrivateRoute from './PrivateRoute';
import ForgotPassword from './ForgotPassword';
import PayoutOperations from './PayoutOperations'; 
import StatisticsReport from './StatisticsReport';
import Deals from './Deals'; // Import the Deals component
import Announcements from './Announcements'; // Import the Deals component


const App = () => {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ForgotPassword />} />
        <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
        <Route path="/questions" element={<PrivateRoute><Questions /></PrivateRoute>} />
        <Route path="/questions/new" element={<PrivateRoute><QuestionForm /></PrivateRoute>} />
        <Route path="/questions/edit/:id" element={<PrivateRoute><QuestionForm /></PrivateRoute>} />
        <Route path="/questions/statistics/:id" element={<PrivateRoute><StatisticsReport /></PrivateRoute>} />
        <Route path="/payouts" element={<PrivateRoute><PayoutOperations /></PrivateRoute>} />
        <Route path="/users" element={<PrivateRoute><Users /></PrivateRoute>} />
        <Route path="/announcements" element={<PrivateRoute><Announcements/></PrivateRoute>} />
        <Route path="/deals" element={<PrivateRoute><Deals /></PrivateRoute>} /> 
      </Routes>
    </div>
  );
};

export default App;
