import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Box,
  Container,
  Typography,
  Autocomplete,
  List,
  ListItem,
  ListItemText,
  IconButton,
  FormControlLabel,
  Switch,
  Slider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { MapContainer, TileLayer, Marker, Circle, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useAuth } from './auth';
import { useNavigate, useParams } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_BASE_USER_SERVER;

// Fix marker icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const LocationMarker = ({ setLatitude, setLongitude, setLocationDetails, radius, setRadius, position, setPosition, setPlace }) => {
  useMapEvents({
    click(e) {
      const { lat, lng } = e.latlng;
      setPosition({ lat, lng });
      setLatitude(lat);
      setLongitude(lng);

      // Perform reverse geocoding
      axios.get(`https://nominatim.openstreetmap.org/reverse`, {
        params: {
          format: 'json',
          lat,
          lon: lng,
          addressdetails: 1,
          'accept-language': 'en'
        }
      })
        .then(response => {
          const address = response.data.address;
          const displayName = response.data.display_name;
          setPlace(displayName);
          setLocationDetails({
            city: address.city || '',
            state: address.state || '',
            county: address.county || '',
            country: address.country || ''
          });
        })
        .catch(error => {
          console.error('Reverse geocoding error', error);
        });
    }
  });

  const handleDragEnd = (e) => {
    const { lat, lng } = e.target.getLatLng();
    setPosition({ lat, lng });
    setLatitude(lat);
    setLongitude(lng);

    // Perform reverse geocoding
    axios.get(`https://nominatim.openstreetmap.org/reverse`, {
      params: {
        format: 'json',
        lat,
        lon: lng,
        addressdetails: 1,
        'accept-language': 'en'
      }
    })
      .then(response => {
        const address = response.data.address;
        const displayName = response.data.display_name;
        setPlace(displayName);
        setLocationDetails({
          city: address.city || '',
          state: address.state || '',
          county: address.county || '',
          country: address.country || ''
        });
      })
      .catch(error => {
        console.error('Reverse geocoding error', error);
      });
  };

  return (
    <>
      {position && position.lat !== undefined && position.lng !== undefined && (
        <>
          <Marker position={position} draggable eventHandlers={{ dragend: handleDragEnd }} />
          <Circle center={position} radius={(radius || 0) * 1000} />
        </>
      )}
    </>
  );
};

const QuestionForm = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();
  const isUpdating = !!id;

  const [text, setText] = useState('');
  const [questionType, setQuestionType] = useState('SINGLE_CHOICE');
  const [answers, setAnswers] = useState([]);
  const [translations, setTranslations] = useState([
    { language: 'fr', text: '' },
    { language: 'de', text: '' },
    { language: 'it', text: '' }
  ]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [place, setPlace] = useState('');
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [radius, setRadius] = useState(5);
  const [position, setPosition] = useState(null);
  const [places, setPlaces] = useState([]);
  const [locationDetails, setLocationDetails] = useState({ city: '', state: '', county: '', country: '' });
  const [file, setFile] = useState(null);
  const [answerFiles, setAnswerFiles] = useState([]);
  const [videoUrl, setVideoUrl] = useState('');
  const [mediaType, setMediaType] = useState('');
  const [international, setInternational] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isUpdating) {
      axios.get(`${apiUrl}/api/questions/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          const question = response.data;
          setText(question.text);
          setQuestionType(question.questionType);
          setAnswers(question.answers || []);
          setTranslations(question.translations || []);
          setPlaces(question.places || []);
          setInternational(question.international);
          setIsPublished(question.published);
        })
        .catch(error => {
          console.error('Error fetching question', error);
        });
    }
  }, [id, isUpdating, token]);

  const handleAutocompleteChange = async (event, value) => {
    if (!value) return;

    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/search`, {
        params: {
          format: 'json',
          q: value,
          'accept-language': 'en'
        }
      });
      const results = response.data.map(result => ({
        label: result.display_name,
        lat: parseFloat(result.lat),
        lon: parseFloat(result.lon)
      }));
      setAutocompleteOptions(results);
    } catch (error) {
      console.error('Error fetching autocomplete options:', error);
      alert('There was an error fetching the autocomplete options. Please try again.');
    }
  };

  const handlePlaceSelect = (event, value) => {
    if (!value) return;
    const lat = parseFloat(value.lat);
    const lon = parseFloat(value.lon);
    setPosition({ lat, lon });
    setLatitude(lat);
    setLongitude(lon);
    setPlace(value.label);

    axios.get(`https://nominatim.openstreetmap.org/reverse`, {
      params: {
        format: 'json',
        lat,
        lon,
        addressdetails: 1,
        'accept-language': 'en'
      }
    })
      .then(response => {
        const address = response.data.address;
        setLocationDetails({
          city: address.city || '',
          state: address.state || '',
          county: address.county || '',
          country: address.country || ''
        });
      })
      .catch(error => {
        console.error('Reverse geocoding error', error);
      });
  };

  const addPlace = () => {
    if (place && latitude !== null && longitude !== null) {
      setPlaces([...places, { name: place, lat: latitude, lon: longitude, radius: radius || 0, ...locationDetails }]);
      setPlace('');
      setLatitude(null);
      setLongitude(null);
      setPosition(null);
      setRadius(5);
      setLocationDetails({ city: '', state: '', county: '', country: '' });
    }
  };

  const handleDeletePlace = (index) => {
    const newPlaces = [...places];
    newPlaces.splice(index, 1);
    setPlaces(newPlaces);
  };

  const addAnswer = () => {
    setAnswers([...answers, { answerText: '', link: '', translations: [
      { language: 'fr', text: '' },
      { language: 'de', text: '' },
      { language: 'it', text: '' }
    ] }]);
  };

  const handleAnswerChange = (index, field, value) => {
    const newAnswers = [...answers];
    newAnswers[index][field] = value;
    setAnswers(newAnswers);
  };

  const handleAnswerTranslationChange = (index, translationIndex, field, value) => {
    const newAnswers = [...answers];
    newAnswers[index].translations[translationIndex][field] = value;
    setAnswers(newAnswers);
  };

  const handleAnswerFileChange = (index, e) => {
    const newFiles = [...answerFiles];
    newFiles[index] = e.target.files[0];
    setAnswerFiles(newFiles);
  };

  const handleDeleteAnswer = (index) => {
    const newAnswers = [...answers];
    newAnswers.splice(index, 1);
    setAnswers(newAnswers);
  };

  const handleTranslationChange = (index, field, value) => {
    const newTranslations = [...translations];
    newTranslations[index][field] = value;
    setTranslations(newTranslations);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleMediaTypeChange = (event) => {
    setMediaType(event.target.value);
    setFile(null);
    setVideoUrl('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const newQuestion = {
      text,
      questionType,
      answers,
      translations,
      placeRequests: places.map(place => ({
        lat: place.lat,
        lon: place.lon,
        radius: place.radius,
        city: place.city,
        state: place.state,
        county: place.county,
        country: place.country,
        translations: place.translations || []
      })),
      international,
      published: isPublished,
      mediaType,
      videoUrl
    };

    const url = isUpdating
      ? `${apiUrl}/api/questions/updateWithMedia/${id}`
      : `${apiUrl}/api/questions/createWithMedia`;
    const method = isUpdating ? 'put' : 'post';

    const data = new FormData();
    data.append('questionRequest', JSON.stringify(newQuestion));
    if (file) {
      data.append('file', file);
    }
    answerFiles.forEach((file, index) => {
      if (file) {
        data.append('answerImages', file);
      }
    });

    axios({
      method: method,
      url: url,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        navigate('/questions');
      })
      .catch(error => {
        console.error(`Error ${isUpdating ? 'updating' : 'creating'} question`, error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>{isUpdating ? 'Update Question' : 'Create Question'}</Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box mb={2}>
              <TextField
                fullWidth
                label="Text"
                value={text}
                onChange={(e) => setText(e.target.value)}
                required
                disabled={loading}
              />
            </Box>
            <Box mb={2}>
              <TextField
                select
                fullWidth
                label="Question Type"
                value={questionType}
                onChange={(e) => setQuestionType(e.target.value)}
                SelectProps={{
                  native: true,
                }}
                disabled={loading}
              >
                <option value="SINGLE_CHOICE">Single Choice</option>
                <option value="MULTIPLE_CHOICE">Multiple Choice</option>
              </TextField>
            </Box>
            <FormControlLabel
              control={
                <Switch
                  checked={international}
                  onChange={(e) => setInternational(e.target.checked)}
                  name="international"
                  color="primary"
                  disabled={loading}
                />
              }
              label="International"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={isPublished}
                  onChange={(e) => setIsPublished(e.target.checked)}
                  name="isPublished"
                  color="primary"
                  disabled={loading}
                />
              }
              label="Published"
            />
            <Box mb={2}>
              {translations.map((translation, index) => (
                <Box key={index} mb={2}>
                  <TextField
                    fullWidth
                    label={`Translation (${translation.language})`}
                    value={translation.text}
                    onChange={(e) => handleTranslationChange(index, 'text', e.target.value)}
                    required
                    disabled={loading}
                  />
                </Box>
              ))}
            </Box>
            <Box mb={2}>
              <Button variant="contained" color="primary" onClick={addAnswer} startIcon={<AddIcon />} disabled={loading}>
                Add Answer
              </Button>
            </Box>
            {answers.map((answer, index) => (
              <Box key={index} mb={4}>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    label={`Answer ${index + 1}`}
                    value={answer.answerText}
                    onChange={(e) => handleAnswerChange(index, 'answerText', e.target.value)}
                    required
                    disabled={loading}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    label="Link"
                    value={answer.link}
                    onChange={(e) => handleAnswerChange(index, 'link', e.target.value)}
                    disabled={loading}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    type="file"
                    fullWidth
                    margin="normal"
                    onChange={(e) => handleAnswerFileChange(index, e)}
                    disabled={loading}
                  />
                </Box>
                {answer.translations && answer.translations.map((translation, tIndex) => (
                  <Box key={tIndex} mb={2}>
                    <TextField
                      fullWidth
                      label={`Translation (${translation.language})`}
                      value={translation.text}
                      onChange={(e) => handleAnswerTranslationChange(index, tIndex, 'text', e.target.value)}
                      disabled={loading}
                    />
                  </Box>
                ))}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDeleteAnswer(index)}
                  startIcon={<RemoveIcon />}
                  disabled={loading}
                >
                  Delete Answer
                </Button>
              </Box>
            ))}

            <Box mb={2}>
              <FormControl fullWidth>
                <InputLabel>Media Type</InputLabel>
                <Select
                  value={mediaType}
                  onChange={handleMediaTypeChange}
                  disabled={loading}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="image">Image</MenuItem>
                  <MenuItem value="video">Video</MenuItem>
                  <MenuItem value="youtube">YouTube</MenuItem>
                  <MenuItem value="vimeo">Vimeo</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {mediaType === 'image' && (
              <Box mb={2}>
                <TextField
                  type="file"
                  fullWidth
                  margin="normal"
                  onChange={handleFileChange}
                  disabled={loading}
                />
              </Box>
            )}
            {mediaType === 'video' && (
              <Box mb={2}>
                <TextField
                  type="file"
                  fullWidth
                  margin="normal"
                  onChange={handleFileChange}
                  disabled={loading}
                />
              </Box>
            )}
            {(mediaType === 'youtube' || mediaType === 'vimeo') && (
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Video URL"
                  value={videoUrl}
                  onChange={(e) => setVideoUrl(e.target.value)}
                  required
                  disabled={loading}
                />
              </Box>
            )}
            <Box mb={2} display="flex" alignItems="center">
              <Autocomplete
                freeSolo
                options={autocompleteOptions}
                onInputChange={handleAutocompleteChange}
                onChange={handlePlaceSelect}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search for a place"
                    value={place}
                    onChange={(e) => setPlace(e.target.value)}
                    fullWidth
                    disabled={loading}
                  />
                )}
                style={{ flex: 1 }}
              />
              <Button variant="contained" color="primary" onClick={addPlace} style={{ marginLeft: '10px' }} disabled={loading}>
                Add Place
              </Button>
            </Box>
            <Box mb={2}>
              <Typography gutterBottom>Radius (km)</Typography>
              <Slider
                value={radius}
                onChange={(e, newValue) => setRadius(newValue)}
                aria-labelledby="radius-slider"
                min={1}
                max={50}
                valueLabelDisplay="auto"
                disabled={loading}
              />
            </Box>
            <List>
              {places.map((place, index) => (
                <ListItem key={index} secondaryAction={
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDeletePlace(index)} disabled={loading}>
                    <DeleteIcon />
                  </IconButton>
                }>
                  <ListItemText
                    primary={place.name}
                    secondary={`Radius: ${place.radius} km`}
                  />
                  <Box>
                    <Typography gutterBottom>Radius (km)</Typography>
                    <Slider
                      value={place.radius || 0}
                      onChange={(e, value) => {
                        const newPlaces = [...places];
                        newPlaces[index].radius = value;
                        setPlaces(newPlaces);
                      }}
                      aria-labelledby="place-radius-slider"
                      min={1}
                      max={50}
                      valueLabelDisplay="auto"
                      disabled={loading}
                    />
                  </Box>
                </ListItem>
              ))}
            </List>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {isUpdating ? 'Update Question' : 'Create Question'}
            </Button>
            {loading && <CircularProgress />}
          </Grid>
          <Grid item xs={12} md={6}>
            <MapContainer center={[51.505, -0.09]} zoom={13} style={{ height: "900px", width: "170%", marginTop: "20px" }}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <LocationMarker
                setLatitude={setLatitude}
                setLongitude={setLongitude}
                setLocationDetails={setLocationDetails}
                radius={radius}
                setRadius={setRadius}
                position={position}
                setPosition={setPosition}
                setPlace={setPlace}
              />
              {places.map((place, index) => (
                <Marker
                  key={index}
                  position={[place.lat, place.lon]}
                  eventHandlers={{
                    click: () => handleDeletePlace(index),
                    dragend: (e) => {
                      const { lat, lng } = e.target.getLatLng();
                      const newPlaces = [...places];
                      newPlaces[index] = { ...newPlaces[index], lat, lon: lng };
                      setPlaces(newPlaces);
                      axios.get(`https://nominatim.openstreetmap.org/reverse`, {
                        params: {
                          format: 'json',
                          lat,
                          lon: lng,
                          addressdetails: 1,
                          'accept-language': 'en'
                        }
                      })
                        .then(response => {
                          const address = response.data.address;
                          newPlaces[index] = {
                            ...newPlaces[index],
                            lat,
                            lon: lng,
                            name: response.data.display_name,
                            city: address.city || '',
                            state: address.state || '',
                            county: address.county || '',
                            country: address.country || ''
                          };
                          setPlaces(newPlaces);
                        })
                        .catch(error => {
                          console.error('Reverse geocoding error', error);
                        });
                    }
                  }}
                  draggable
                >
                  <Circle center={[place.lat, place.lon]} radius={(place.radius || 0) * 1000} />
                </Marker>
              ))}
            </MapContainer>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default QuestionForm;
